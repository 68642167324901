import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useRefContext } from 'context/RefContext'
import { Button, Container, Link } from '@mui/material'

import html5 from 'static/icons/skills/html5.svg'
import css3 from 'static/icons/skills/css3.svg'
import javascript from 'static/icons/skills/javascript.svg'
import typescript from 'static/icons/skills/typescript.svg'
import react from 'static/icons/skills/react.svg'
import node from 'static/icons/skills/nodejs.svg'
import django from 'static/icons/skills/django.svg'
import python from 'static/icons/skills/python.svg'
import mysql from 'static/icons/skills/mysql.svg'
import figma from 'static/icons/skills/figma.svg'
import notion from 'static/icons/skills/notion.svg'
import wordpress from 'static/icons/skills/wordpress.png'
import aws from 'static/icons/skills/aws.svg'
import docker from 'static/icons/skills/docker.svg'
import git from 'static/icons/skills/git.svg'
import github from 'static/icons/skills/github.svg'
import githubactions from 'static/icons/skills/githubactions.svg'
import nginx from 'static/icons/skills/nginx.svg'




const skills: {logo: any, name: string, color?: string, link?:string}[] = [
	{ logo: html5, name: 'HTML', color: '#e44d26', link: 'https://developer.mozilla.org/fr/docs/Glossary/HTML5' },
	{ logo: css3, name: 'CSS', color: '#264de4', link: 'https://developer.mozilla.org/fr/docs/Web/CSS' },
	{ logo: javascript, name: 'Javascript', color: '#f0db4f', link: 'https://developer.mozilla.org/fr/docs/Web/JavaScript' },
	{ logo: typescript, name: 'Typescript', color: '#017ACC', link: 'https://www.typescriptlang.org/' },
	{ logo: react, name: 'React', color: '#61DAFB', link: 'https://fr.react.dev/' },
	{ logo: node, name: 'NodeJS', color: '#83cd29', link: 'https://nodejs.org/fr' },
	{ logo: django, name: 'Django', color: '#2ba977', link: 'https://www.djangoproject.com/' },
	{ logo: python, name: 'Python', link: 'https://www.python.org/' },
	{ logo: mysql, name: 'MySQL', color: '#00546b', link: 'https://www.mysql.com/fr/' },
	{ name: 'Git', logo: git, color: '#F05130', link: 'https://git-scm.com/'  },
	{ name: 'Github', logo: github, color: '#2B3137', link: 'https://github.com/' },
	{ name: 'Github Actions', logo: githubactions, color: '#2088ff', link: 'https://github.com/features/actions' },
	{ name: 'Docker', logo: docker, color: '#2396ED', link: 'https://www.docker.com/' },
	{ name: 'AWS', logo: aws, color: '#ff9900', link: 'https://aws.amazon.com/' },
	{ name: 'Nginx', logo: nginx, color: '#019639', link: 'https://nginx.org/en/' },
	{name: 'Figma', logo: figma, link: 'https://www.figma.com/' },
	{name: 'Notion', logo: notion, link: 'https://www.notion.so/'},
	{name: 'Wordpress', logo: wordpress, link: 'https://wordpress.com/' }
]




const ServiceView:FC = () => {

	const { t } = useTranslation()

	const { ServiceRef, ContactRef } = useRefContext()

	return (
		<Container ref={ServiceRef} maxWidth='lg' sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', paddingY: 24, gap: 8}}>
			<Box display='flex' flexDirection='column' gap={2} width='100%'>
				<Box display='flex' flexDirection='row' gap={1}>
					<Typography noWrap variant='h2' sx={{ color: theme => theme.palette.primary.main}}>
						{t('From Concept')}
					</Typography>
					<Typography noWrap variant='h2' sx={{ color: theme => theme.palette.secondary.main}}>
						{t('to Code')}
					</Typography>
				</Box>
				<Typography flexWrap='wrap' variant='body2' sx={{ color: theme => theme.palette.primary.main}}>
					{t('I focus on building dynamic applications that integrate frontend and backend seamlessly. My approach emphasizes optimizing performance and delivering responsive designs, ensuring that each line of code contributes to both functionality and scalability.')}
				</Typography>
				<Box>
					<Button
						variant='contained'
						color='primary'
						onClick={() => (ContactRef?.current as any).scrollIntoView({behavior: 'smooth'})}
						sx={{
							borderRadius: '4px',
							marginRight: 2,
							'&:hover': {
								backgroundColor: theme => theme.palette.primary.dark
							}
						}}
					>
						<Typography variant='body2' sx={{ color: theme => theme.palette.primary.contrastText }}>
							{t('Contact me')}
						</Typography>
					</Button>
				</Box>
			</Box>
			<Box display='flex' flexWrap='wrap' flexDirection='row' justifyContent='center' alignItems='center' gap={2} width='100%'>
				{
					skills.map((skill) => (
						<Link
							key={skill.name}
							display='flex'
							justifyContent='center'
							alignItems='center'
							bgcolor={skill.color || '#FFFFFF'}
							borderRadius={3}
							padding={1}
							href={skill.link}
							target='_blank'
							sx={{
								aspectRatio: 1,
								transition: 'all 0.2s ease-in-out',
								':hover': {
									transform: 'scale(1.04)'
								}
							}}
						>
							<img src={skill.logo} alt='skill' width='60px' style={{ aspectRatio: 1 }}/>
						</Link>

					))
				}
			</Box>
		</Container>
	)
}

export default ServiceView
